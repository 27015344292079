import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import Button from '../../../components/Button/Button';
import SubPageNav from '../../../components/SubPageNav/SubPageNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';
import OBDLogo from '../../../svgs/OBDlogo-greyBg.svg';

const HombuyersJourneyStep2Page = () => {
	const intl = useIntl();

	return (
		<Layout
			view='buyer'
			breadcrumb={{
				text: <FormattedMessage id='buyerNav.dashboard' />,
				path: '/new-home-buyer/dashboard/'
			}}
			header={<FormattedMessage id='buyerNav.steps' />}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.step2' })} />
			<Container>
				<Row>
					<Col>
						<SubPageNav
							links={[
								{
									text: <FormattedMessage id='buyerNav.step1' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
								},
								{
									text: <FormattedMessage id='buyerNav.step2' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/',
									active: true
								},
								{
									text: <FormattedMessage id='buyerNav.step3' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
								},
								{
									text: <FormattedMessage id='buyerNav.step4' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
								},
								{
									text: <FormattedMessage id='buyerNav.step5' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
								},
								{
									text: <FormattedMessage id='buyerNav.step6' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/'
								}
							]}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h2>
							<FormattedMessage id='nav.step' /> 2{' '}
							<span className='thin'>
								{' '}
								<FormattedMessage id='buyerNav.step2' />{' '}
							</span>
						</h2>
						<p>
							<FormattedMessage id='homebuyersStep2.contentA' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h3 className='sm'>
							{' '}
							<FormattedMessage id='homebuyersStep2.headerA' />{' '}
						</h3>
						<p>
							<FormattedMessage id='homebuyersStep2.contentB' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow' align='center'>
					<Col md={3}>
						<OBDLogo />
					</Col>
					<Col md={9}>
						<h3 className='sm'>
							{' '}
							<FormattedMessage id='homebuyersStep2.headerB' />{' '}
						</h3>
						<p>
							<FormattedMessage id='homebuyersStep2.contentC' />
						</p>
						<Button
							linkExternal
							linkPath='https://obd.hcraontario.ca'
							text={<FormattedMessage id='linkNames.obd' />}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h3 className='sm'>
							{' '}
							<FormattedMessage id='homebuyersStep2.headerC' />{' '}
						</h3>
						<p>
							<FormattedMessage id='homebuyersStep2.contentD' />
						</p>
					</Col>
				</Row>
			</Container>

			<section>
				<Container>
					<Row align='center'>
						{/* <Col xl={6} md={12}>
                    <Img fluid={data.buildersImg.childImageSharp.fluid} alt=""/>
                  </Col> */}

						<Col>
							<h2>
								<span className='thin'>
									{' '}
									<FormattedMessage id='homebuyersStep2.headerD' />{' '}
								</span>
								<br />
								<FormattedMessage id='homebuyersStep2.headerE' />
							</h2>
							<p>
								<FormattedMessage id='homebuyersStep2.contentE' />
							</p>
							<ul>
								<li>
									<FormattedMessage id='homebuyersStep2.list01A' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01B' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01Bi' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01Bii' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01C' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01Ci' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01D' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01Di' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01E' />
								</li>
								<li>
									<FormattedMessage id='homebuyersStep2.list01F' />
								</li>
							</ul>
							<p>
								<FormattedMessage id='homebuyersStep2.contentF' />{' '}
								<a
									href={intl.formatMessage({
										id: 'homebuyersStep2.link01'
									})}
									aria-label={intl.formatMessage({
										id: 'homebuyersStep2.contentFi'
									})}
								>
									<FormattedMessage id='homebuyersStep2.contentFi' />
								</a>
								. <FormattedMessage id='homebuyersStep2.contentFii' />
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<Container>
				<Row>
					<Col md={12}>
						<SubPageBottomNav
							prevLink={{
								text: <FormattedMessage id='buyerNav.step1' />,
								path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
							}}
							nextLink={{
								text: <FormattedMessage id='buyerNav.step3' />,
								path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default HombuyersJourneyStep2Page;
